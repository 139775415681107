<template>
    <div class="container">
        <div class="header_space"></div>
        <header id="header">
            <div class="wrap">
                <router-link class="logo" to="/light">
                    <img src="@/assets/images/official/logo.png" alt="Light" width="123" />
                </router-link>
                <div class="right">
                    <!-- <template> -->
                    <router-link class="g_btn regist" to="/register">{{$t('common.register')}}</router-link>
                    <router-link class="g_btn login" to="/login">{{$t('common.login')}}</router-link>
                    <!-- </template> -->

                    <el-dropdown trigger="click" @command="langChange">
                        <div class="g_btn lang">
                            <span class="value">{{langList[lang]}}</span>
                            <div class="arrow">
                                <i class="iconfont2 icon-right_arrow"></i>
                            </div>
                        </div>
                        <el-dropdown-menu slot="dropdown" class="official_dropdown">
                            <el-dropdown-item v-for="(item,key,index) in langList" :key="index" :command="key">{{item}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </header>

        <div class="main">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import langList from '@/assets/js/langList'
export default {
    data(){
        return{
            langList:langList
        }
    },
    computed:{
		...mapGetters(['lang']),
    },
    methods:{
        langChange(value) {
            this.$i18n.locale = value;
            this.$store.dispatch('app/setLangOnly',value).then(()=>{//切换语言
                // 获取会员套餐列表
                if(this.$route.path=='/light'&&value!='zh'){
                    this.$store.dispatch('markets/getVipList')
                }
            })

            // if(this.$route.path=='/light'){
            //     // 如果当前页是light，且切换成中文则跳转至中文页面
            //     if(value=='zh'){
            //         this.$router.replace({path:'/lightZH'})
            //     }
            // }
            // // 如果当前页是lightZH，且切换成非中文则跳转至首页（light）切换语言
            // if(this.$route.path=='/lightZH'||this.$route.path=='/lightZH/'){
            //     if(value!='zh'){
            //         this.$router.replace({path:'/light'})
            //     }
            // }
            // if(this.$route.path=='/problem'){
            //     // 如果当前页是problem，且切换成中文则跳转至中文页面
            //     if(value=='zh'){
            //         this.$router.replace({path:'/problemZH'})
            //     }
            // }
            // // 如果当前页是problemZH，且切换成非中文则跳转至常规页切换语言
            // if(this.$route.path=='/problemZH'||this.$route.path=='/problemZH/'){
            //     if(value!='zh'){
            //         this.$router.replace({path:'/problem'})
            //     }
            // }
            
        },
    },
}
</script>
<style scoped="scoped" lang="stylus">
.container{
    .header_space{
        // height: 88px
        height: 0.36rem;
        max-height: 36px;
        
        padding: 20px 0;
    }
    #header{
        position: fixed
        top 0
        left 0
        right 0
        z-index 9
        background #172038
        color: #fff
        
        height: 0.36rem;
        max-height: 36px;
        // height: 48px;

        padding: 20px 0;
        .wrap{
            width calc(1280 / 1920 * 100%)
            max-width 1280px
            margin: 0 auto
            display: flex
            align-items: center
            justify-content: space-between
        }
        @media only screen and (max-width: 993px) {
            .wrap {
                width: auto;
                padding: 0 6%;
            }
        }
        .logo{
            width: 120px

            height: 0.36rem
            max-height: 36px;
            // height: 48px;

            display: flex
            align-items: center
            cursor pointer
            img{
                width 100%
            }
        }
        .right{
            display: flex
            align-items: center

            height: 0.36rem
            max-height: 36px;
            // height: 48px;

            font-size 0.15rem
            .regist,
            .login{
                // padding: 0.08rem 0.3rem
                padding: 2px 5px
                min-width 0.92rem
                height: 100%
                box-sizing: border-box

                border-radius: 0.3rem;
                border: 1px solid rgba(255, 255, 255, 0.2);
                margin-right: 0.3rem
                color: var(--fontWhite)
            }
            .regist{
                background: rgba(0, 0, 0, 0.11);
            }
            .login{
                // background: #5655E5;
                background: #5655E5 linear-gradient(113deg, #57BEFB 0%, #6374E6 100%);
            }
            .lang{
                color: #fff
                .value{
                    margin-right: 5px
                }
                .arrow{
                    margin-top 2px
                    transform: rotate(90deg)
                    .iconfont2{
                        font-size 12px
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1920px) {
        #header {
            padding: calc(20 / 1920 * 100vw) 0;
        }
        .header_space {
            //height: 48px;
            padding: calc(20 / 1920 * 100vw) 0;
        }
    }
    @media only screen and (max-width: 1440px) {
        .header_space,
        #header{
            height: 35px;
            .logo{
                width 100px
                height: 35px
            }
            .right{
                height: 35px
            }
        }
    }
    @media only screen and (max-width: 750px) {
        .header_space,
        #header{
            padding: 10px 0;
            .logo{
                img{
                    width: 80px
                }
            }
            .right{
                height: 30px
            }
        }
    }
}
</style>